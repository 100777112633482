import { Avatar, Col, Row, Space, Typography, Image, Skeleton } from "antd";
import React from "react";
import { EditionJson } from "../../../../../../../api";
import { IStylistCardProps } from ".";
import { convertToCloudFareUrl } from "../../../../../../../api/cloudfare";
import { useDataLoader } from "../../../../../../../hooks/useData";
import { Number } from "schema-dts";
import { COMB_LOGO_SRC } from "../../../../../../../consts";

const MiniProfile = ({
  stylistId,
  profileImage,
  name,
  bio,
}: IStylistCardProps) => {
  const res = useDataLoader(
    async () => await EditionJson.getStylistPhotos(stylistId),
    []
  );

  if (res.isLoading) {
    return <Skeleton active avatar paragraph={{ rows: 4 }} />;
  }

  const totalHaircuts = res && res.data ? res.data.stats.totalHaircuts : 0;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space>
        <Avatar
          size="large"
          src={profileImage ? profileImage : COMB_LOGO_SRC}
        />
        <Typography.Title level={4} className="is-marginless">
          {name}
        </Typography.Title>
      </Space>
      <Typography.Paragraph>{bio}</Typography.Paragraph>

      {totalHaircuts > 100 && (
        <Typography.Paragraph>
          <Typography.Text strong>Professional stats</Typography.Text>
          <br />
          {name} has been serving{" "}
          {res && res.data ? res.data.stats.totalHaircuts : ""} haircuts at EE.
        </Typography.Paragraph>
      )}

      {res.data && res.data.reviews.length > 0 ? (
        <>
          <Typography.Paragraph>
            <Typography.Text strong>From customers</Typography.Text>
            <br />
            {res.data.reviews.slice(0, 3).map((review: any, i: Number) => (
              <Typography.Paragraph
                ellipsis={{ expandable: true, symbol: "more" }}
                key={`review-${i}`}
                className="mb-0"
                italic
              >
                "{review}"
              </Typography.Paragraph>
            ))}
          </Typography.Paragraph>
        </>
      ) : (
        <></>
      )}

      <div>
        <>
          {res.data && res.data.photos.length > 0 ? (
            <>
              <Row gutter={[20, 20]}>
                {res.data.photos.map((photo: any) => {
                  const img = convertToCloudFareUrl(photo.id);
                  return (
                    <Col key={img} xs={24} sm={6}>
                      <Image
                        width={"100%"}
                        height={280}
                        src={img}
                        preview={{ mask: <div></div> }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div></div>
          )}
        </>
      </div>
    </Space>
  );
};

export default MiniProfile;
