const PASADENA_ID = "5db31cd6d9359728270cdcaf";
const SAN_GABRIEL_ID = "5db31cd6d9359728270cdcad";
const DTLA_ID = "4db31cd6d9359728270cdcad";
const MW_ID = "655a3ea272b48d4e2e938759";

export const DEFAULT_STYLISTS = [
  {
    pathId: "eddie",
    name: "Eddie",
    locations: "Pasadena, Mid Wilshire",
  },
  {
    pathId: "kim",
    name: "Kim",
    locations: "San Gabriel",
  },
  {
    pathId: "lianne",
    name: "Lianne",
    locations: "San Gabriel",
  },
  {
    pathId: "celine",
    name: "Celine",
    locations: "San Gabriel",
  },
  {
    pathId: "jane",
    name: "Jane",
    locations: "San Gabriel",
  },
  {
    pathId: "carla",
    name: "Carla",
    locations: "Pasadena",
  },
  {
    pathId: "danny",
    name: "Danny",
    locations: "Arts Disrtict",
  },
  {
    pathId: "kristy",
    name: "Kristy",
    locations: "Pasadena",
  },
  {
    pathId: "connie",
    name: "Connie",
    locations: "San Gabriel",
  },
  {
    pathId: "gina",
    name: "Gina",
    locations: "Pasadena",
  },
  {
    pathId: "hourofthewolfbarbering",
    name: "Emmanuel",
    locations: "Arts Disrtict",
  },
  {
    pathId: "yumi",
    name: "Yumi",
    locations: "San Gabriel",
  },
  {
    pathId: "chloekim",
    name: "Chloe",
    locations: "Arts Disrtict",
  },
  {
    pathId: "melvin",
    name: "Melvin",
    locations: "Arts Disrtict",
  },
  {
    pathId: "jinha",
    name: "Elise",
    locations: "Pasadena",
  },
  {
    pathId: "don",
    name: "Don",
    locations: "Arts Disrtict",
  },
  {
    pathId: "olivia",
    name: "Olivia",
    locations: "Pasadena",
  },
  {
    pathId: "hin",
    name: "Hin",
    locations: "Arts Disrtict",
  },
  {
    pathId: "victor",
    name: "Victor",
    locations: "Mid Wilshire",
  },
  {
    pathId: "yuna",
    name: "Yuna",
    locations: "Mid Wilshire",
  },
  {
    pathId: "vanilla",
    name: "Van",
    locations: "Mid Wilshire",
  },
  {
    pathId: "joanne",
    name: "Joanne",
    locations: "San Gabriel",
  },
  {
    pathId: "hannah",
    name: "Hannah",
    locations: "Arts Disrtict",
  },
  {
    pathId: "erin",
    name: "Erin",
    locations: "Pasadena",
  },
  {
    pathId: "sean",
    name: "Sean",
    locations: "San Gabriel",
  },
  {
    pathId: "leah",
    name: "Leah",
    locations: "Mid Wilshire",
  },
  {
    pathId: "justinhair",
    name: "Justin",
    locations: "Mid Wilshire",
  },
  {
    pathId: "jojo",
    name: "Jojo",
    locations: "Pasadena",
  },
];

/*
  This needs to be mated with edition-biz
*/

export type DefaultServiceType = {
  title: string;
  description?: string;
  category: number;
  option?: {
    id: string;
    title: string;
  };
};
export const defaultServices: DefaultServiceType[] = [
  {
    title: "Men's Haircut",
    category: 1,
    description:
      "A precision cut tailored to men's style preferences. This service includes shampoo, and styling.",
  },
  {
    title: "Women's Haircut",
    category: 2,
    description:
      "A personalized haircut designed for women, considering the current trends and individual style. Includes shampoo and blow-dry.",
  },
  {
    title: "Men's Perm",
    category: 3,
    description:
      "Experience a transformative Korean-style Men's Perm, complete with a customized haircut for a complete makeover. Tailored to your preference, from gentle waves to defined curls, our service ensures a personalized look. *HAIRCUT INCLUDED",
  },
  {
    title: "Down Perm",
    category: 10,
    description:
      "Ideal for achieving a neat, side-swept look, our Down Perm service expertly flattens and tames unruly side hair for a polished appearance.",
  },
  {
    title: "Color",
    category: 4,
    description:
      "Reveal a vibrant new you. From highlights to full-color transformations, our expert stylists will achieve your desired hue. Consultation required.",
  },
  {
    title: "Kid's Haircut",
    category: 5,
    description:
      "A quick and stylish haircut designed for kids with short hair. Fun and fuss-free for a great look.",
  },
  {
    title: "Beard Trim",
    category: 9,
    description:
      "Neaten up with our beard trim service. Our barbers shape and trim to enhance your facial features.",
  },
  // {
  //   title: "Men's Long Haircut",
  //   category: 11,
  //   description:
  //     "A tailored cut for men with longer hair, emphasizing style and individuality. This service, including a wash and style, is specially priced due to the additional time reserved for achieving the perfect look.",
  // },
  // {
  // {
  //   title: "Kid's Long Cut",
  //   category: 8,
  //   description:
  //     "Tailored for kids with longer hair. Our stylists ensure a comfortable experience, leaving your child looking their best.",
  // },
];

export const defaultServiceObject = defaultServices.reduce<{
  [key: number]: DefaultServiceType;
}>((accumulator, service) => {
  accumulator[service.category] = service;
  return accumulator;
}, {});

export const SERVICES_CODES_FOR_CONSULTATION = [4];

export const DEFAULT_LOCATIONS = [
  {
    _id: MW_ID,
    name: "Mid Wilshire",
    openingHours: "Mo,Tu,We,Th,Fr,Sa 10:00-19:00",
    phone: "16268888052",
    parking: {
      map: "https://www.google.com/maps/d/viewer?mid=1ixdg11DHkKR2kDpQz_p7BoY5sWvrMv0&usp=sharing",
      description:
        "We have private parking lots at the back side of the building, and there is also plenty of street parking available. Please refer to our interactive parking map for more details.",
    },
    isVerified: true,
    links: {
      yelp: "https://www.yelp.com/biz/eddies-edition-mid-wilshire-los-angeles",
      instagram: "https://www.instagram.com/eddies_edition/",
    },
    map: "https://goo.gl/maps/BNmTVFsMHi6QiQKP8",
    pathId: "mid-wilshire",
    city: "Los Angeles",
    address: "4045 Wilshire Blvd",
    zipcode: "90010",
    state: "CA",
    lat: 34.0618733,
    lng: -118.3160202,
    images: [],
    priority: true,
    services: [1, 2, 3, 4, 5, 8],
    description:
      "Find us at the bustling ROW DTLA. Amidst a tapestry of trendy shops and gourmet restaurants, Eddie's Edition offers a refreshing sanctuary of style and sophistication. After your transformation, explore the vibrant surroundings that encapsulate modern urban charm.",
    banners: [],
    thumbnail: "/thumbnails/mw-thumbnail.jpg",
  },
  {
    _id: DTLA_ID,
    name: "Arts District",
    openingHours: "Mo,Tu,We,Th,Fr,Sa 10:00-19:00",
    phone: "16268888052",
    parking: {
      image:
        "https://eddiesedition.s3.us-west-2.amazonaws.com/maps/EE-ROW-MAP.png",
      map: "https://www.google.com/maps/d/edit?mid=1j_2PRSJb-RWNq708MTg1tgEe20fJVNUB&usp=sharing",
      description:
        "Parking structure, 2 hours free. Please check our sitemap if you are first time.",
    },
    links: {
      yelp: "https://www.yelp.com/biz/eddies-edition-dtla-los-angeles",
      google: "https://g.page/eddie-s-edition-san-gabriel?share",
      instagram: "https://www.instagram.com/eddies_edition/",
    },
    map: "https://goo.gl/maps/BNmTVFsMHi6QiQKP8",
    pathId: "dtla",
    city: "Los Angeles",
    address: "1320 E 7th st #136",
    zipcode: "90021",
    state: "CA",
    lat: 34.0333496428236,
    lng: -118.242184893363,
    images: [
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/dtla-0.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/dtla-1.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/dtla-2.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/dtla-3.jpg",
    ],
    services: [1, 2, 3, 4, 5, 8, 9],
    thumbnail: "/thumbnails/dtla-thumbnail.jpg",
  },
  {
    _id: SAN_GABRIEL_ID,
    name: "San Gabriel",
    openingHours: "Mo,Tu,We,Th,Fr,Sa 10:00-19:00",
    phone: "16268888052",
    map: "https://g.page/eddie-s-edition-san-gabriel?share",
    parking: {
      map: "https://www.google.com/maps/d/embed?mid=1VejWLLXMVL9rr4Sf1XEpWvYG3BTT4l8I",
      description: "Free & Easy street parking, Private lots",
    },
    links: {
      yelp: "https://www.yelp.com/biz/eddie-s-edition-san-gabriel-san-gabriel",
      google: "https://g.page/eddie-s-edition-san-gabriel?share",
      instagram: "https://www.instagram.com/eddies_edition/",
    },
    pathId: "san-gabriel",
    city: "San Gabriel",
    address: "123 North San Gabriel Blvd",
    zipcode: "91775",
    state: "CA",
    lat: 34.1042892,
    lng: -118.0912548,
    images: [
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/sg-1.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/sg-2.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/sg-3.jpg",
    ],
    services: [1, 2, 3, 4, 5, 8],
    thumbnail: "/thumbnails/sg-thumbnail.jpg",
  },
  {
    _id: PASADENA_ID,
    name: "Pasadena",
    phone: "16268888052",
    openingHours: "Mo,Tu,We,Th,Fr,Sa 08:30-18:30",
    map: "https://goo.gl/maps/8qMkvXZijY5VRAZu5",
    parking: {
      map: "https://www.google.com/maps/d/u/0/viewer?mid=1B8ZKHrOd9yIblWTCByDdSrOHUZOh7fdA&ll=34.147719290076715%2C-118.13125329128708&z=19",
      description: "Free & Easy street parking, Private lots",
    },
    pathId: "pasadena",
    city: "Pasadena",
    address: "914 E Union st",
    zipcode: "91106",
    state: "CA",
    lat: 34.1475969,
    lng: -118.1314463,
    links: {
      yelp: "https://www.yelp.com/biz/eddies-edition-pasadena-pasadena",
      google:
        "https://www.google.com/maps/place/Eddie's+Edition+-+Pasadena/@34.1475969,-118.1314463,15z/data=!4m5!3m4!1s0x0:0xf1adfc2336088a89!8m2!3d34.1475961!4d-118.1315757",
    },
    images: [
      "/pasadena/1.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/pasadena-1.jpg",
      "https://eddiesedition.s3-us-west-2.amazonaws.com/biz-images/pasadena-3.jpg",
    ],
    services: [1, 2, 3, 4, 5, 8],
    thumbnail: "/thumbnails/ps-thumbnail.jpg",
  },
];

export const MENS_HAIRCUT_EXTRA_OPTIONS = [
  {
    id: "mens-skin-fade",
    title: "Skin Fade",
    description: "Barbershop level sharp fades.",
  },
  {
    id: "mens-asian-hair",
    title: "Asian Hair",
    description: "Straight and thick asian haircut.",
  },
  {
    id: "mens-long-hair",
    title: "Long Hair",
    description: "Hairs more than shoulder length.",
  },
  {
    id: "mens-touch-up",
    title: "Touch Up",
    description: "Touch up for the haircut within 2 weeks.",
    duration: 20,
    price: 25,
  },
];

export const WOMENS_HAIRCUT_EXTRA_OPTIONS = [
  {
    id: "women-short-haircut",
    title: "Women's Short Haircut",
    description: "Shorter than boblength, pixie, or any other short haircut.",
  },
  {
    id: "womens-long-haircut",
    title: "Women's Long Haircut",
    description: "Hairs more than shoulder length.",
  },
];

export const MENS_PERM_EXTRA_OPTIONS = [
  {
    id: "mens-down-perm",
    title: "Down Perm",
    description: "Tames hair that sticks out on the sides.",
  },
  {
    id: "mens-long-hair-perm",
    title: "Long Hair Perm",
    description: "Hairs more than shoulder length.",
  },
];

export const KIDS_HAIRCUT_OPTIONS = [
  {
    id: "kids-short-haircut",
    title: "Kid's Short Haircut",
    description: "Shorter than boblength, pixie, or any other short haircut.",
  },
  {
    id: "kids-long-haircut",
    title: "Kid's Long Haircut",
    description: "Hairs more than shoulder length.",
  },
];

/*
  Service extra options by service code
*/
type ExtraOptionsType = {
  [key: number]: any[];
};
export const EXTRA_OPTIONS: ExtraOptionsType = {
  1: MENS_HAIRCUT_EXTRA_OPTIONS,
  2: WOMENS_HAIRCUT_EXTRA_OPTIONS,
  // 3: MENS_PERM_EXTRA_OPTIONS,
  5: KIDS_HAIRCUT_OPTIONS,
};
